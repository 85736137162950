<template>
  <div class="form-group">
    <label class="form-group__container">
      <span
        v-if="label"
        :class="['form-group__label', disabled ? 'form-group__label_disabled' : '']"
        >{{ label }}</span
      >
      <input
        @input.trim="Change__Text($event)"
        :value="value"
        type="text"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :minlength="minlength"
        :hidden="hidden"
        :keyAdd="unique"
        :pattern="pattern"
        :class="['form-group__input', value ? 'form-group__input_complete' : '']"
        @keyup.enter="emitOnEnter ? $root.$emit('keyUpEnter') : ''"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "InputMain",
  data() {
    return {
      value: ""
    };
  },
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: String
    },
    minlength: {
      type: String
    },
    keyUpEnter: {
      type: Function
    },
    tooltip: {
      type: String
    },
    keyAdd: {
      type: [String, Number]
    },
    pattern: {
      type: String
    },
    unique: {
      type: [String, Number]
    },
    editValue: {
      type: [String, Number]
    },
    styleInput: {
      type: [String, Number, Array, Object]
    }
  },
  mixins: [],
  mounted() {
    this.$root.$on(`input-text:clear_${this.unique}`, () => {
      this.value = "";
    });
    if (!this.value) {
      this.value = null;
    }
    if (this.editValue) {
      this.value = this.editValue;
    }
  },
  methods: {
    Change__Text(event) {
      this.value = event.target.value;
      let data = {
        eventName: "Change__Text",
        data: {
          event: event,
          placeholder: this.placeholder,
          value: this.value,
          readonly: this.disabled,
          label: this.label,
          maxlength: this.maxlength,
          keyAdd: this.keyAdd,
          tooltip: this.tooltip,
          pattern: this.pattern,
          disabled: this.disabled,
          unique: this.unique
        }
      };
      this.$root.$emit(`text:change_${this.unique}`, this.value);
    }
  },
  computed: {
    emitOnEnter() {
      return this.keyUpEnter || false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index.scss";
@import "~@/assets/base/color/colors.scss";

@mixin input-box-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.03);
}

.form-group {
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      z-index: 2;
      background: #f2f2f7;
    }
  }

  &__label {
    &_disabled {
      color: $text-disabled;
    }
  }

  &__input {
    position: relative;
    padding: 7px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    border: none;
    outline: none;
    transition: all 0.3s ease;

    //&:focus {
    //  border: 2px solid $action-primary;
    //  border-radius: 5px;
    //}

    &:disabled {
      color: $text-disabled;
    }

    &::placeholder {
      font-family: inherit;
      color: $text-disabled;
    }
  }
}
</style>
